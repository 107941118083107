import * as stylex from '@stylexjs/stylex';
import { styles } from './styles.ts';
import React, { useEffect, useState } from 'react';
import FirstStepForm from '@entities/first-step-form/index.tsx';
import SecondStepForm from '@entities/second-step-form/index.tsx';
import { CreateEventFormValues } from '@shared/types/types.ts';
import { Button } from '@sminex/sminex-uikit';
import { useNavigate } from 'react-router-dom';
import { useAddEvent } from '@features/api/use-create-event.ts';
import { useAddSegment } from '@features/api/use-create-segment.ts';
import { useCreateUser } from '@features/api/use-create-user.ts';
import { useCreateTickets } from '@features/api/use-create-tickets.ts';
import { useToast } from '@shared/hooks/useToast.ts';

interface CreateEventFormProps {
  currentStep: number;
  nextStepHandle: () => void;
  prevStepHandle: () => void;
}

const CreateEventForm: React.FC<CreateEventFormProps> = ({ currentStep, nextStepHandle, prevStepHandle }) => {
  const [eventFormValues, setEventFormValues] = useState<CreateEventFormValues>({
    name: '',
    date: [],
    file: null,
    segments: [],
  });

  const onFileChange = (files: File) => {
    setEventFormValues({ ...eventFormValues, file: files });
  };

  const navigate = useNavigate();

  const { addEvent, error: eventError } = useAddEvent();
  const { addSegment, error: segmentError } = useAddSegment();
  const { createUser, error: userError } = useCreateUser();
  const { createTickets, error: ticketsError } = useCreateTickets();
  const { showToast } = useToast();

  useEffect(() => {
    switch (true) {
      case !!eventError:
        showToast('Ошибка при создании мероприятия', 'error');
        break;
      case !!segmentError:
        showToast('Ошибка при создании сегмента', 'error');
        break;
      case !!userError:
        showToast('Ошибка при создании внешнего пользователя', 'error');
        break;
      case !!ticketsError:
        showToast('Ошибка при добавлении участников', 'error');
        break;
      default:
        break;
    }
  }, [eventError, segmentError, userError, ticketsError]);

  const createEventHandler = async () => {
    const variables = {
      name: eventFormValues.name,
      dateStart: eventFormValues.date[0],
      dateEnd: eventFormValues.date[1],
    };

    const response = await addEvent(variables);
    navigate('/');
    if (eventError) {
      return;
    }
    const eventId = response.data.createEvent.id;

    if (eventFormValues.segments) {
      for (const segment of eventFormValues.segments) {
        const result = await addSegment({ title: segment.name, eventId });
        const usersId = [];
        if (segment.freelanceEmployee.length > 0) {
          for (const onefreelanceEmployee of segment.freelanceEmployee) {
            const user = await createUser(onefreelanceEmployee);
            usersId.push(user.data.createUser.id);
          }
        }
        usersId.concat(segment.companiesStructureList);
        const allUsersId = usersId.concat(segment.companiesStructureList);
        await createTickets({
          usersId: allUsersId,
          eventId,
          segmentId: result.data.createTicketSegment.id,
        });
      }
    }
  };

  const formatDate = (input: string): string => {
    const [day, month, year]: string[] = input.split('/');

    const date = new Date(Number(year), Number(month) - 1, Number(day));

    const formattedYear = date.getFullYear();
    const formattedMonth = (date.getMonth() + 1).toString().padStart(2, '0');
    const formattedDay = date.getDate().toString().padStart(2, '0');

    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    return `${formattedYear}-${formattedMonth}-${formattedDay} ${hours}:${minutes}:${seconds}`;
  };

  const onChangeDateHandler = (newDateArr: string[]) => {
    const result = [];
    const dateStart = new Date(newDateArr[0]);
    const dateEnd = new Date(newDateArr[1]);

    const dayStart = dateStart.getDate().toString().padStart(2, '0');
    const monthStart = (dateStart.getMonth() + 1).toString().padStart(2, '0');
    const yearStart = dateStart.getFullYear();

    const dayEnd = dateEnd.getDate().toString().padStart(2, '0');
    const monthEnd = (dateEnd.getMonth() + 1).toString().padStart(2, '0');
    const yearEnd = dateEnd.getFullYear();

    const formattedDateStart = `${dayStart}/${monthStart}/${yearStart}`;
    const formattedDateEnd = `${dayEnd}/${monthEnd}/${yearEnd}`;

    result.push(formatDate(formattedDateStart), formatDate(formattedDateEnd));
    setEventFormValues({ ...eventFormValues, date: result });
  };

  const formSteps = [
    <FirstStepForm
      eventFormValues={eventFormValues}
      onChangeDate={(newDate) => onChangeDateHandler(newDate)}
      onFileChange={onFileChange}
      onChangeName={(value) => setEventFormValues({ ...eventFormValues, name: value })}
    />,
    <SecondStepForm setEventFormValues={setEventFormValues} eventFormValues={eventFormValues} />,
  ];

  return (
    <>
      <div>
        <p {...stylex.props(styles.title)}>Создать мероприятие</p>
        <form {...stylex.props(styles.form)}>{formSteps[currentStep]}</form>
      </div>
      <div {...stylex.props(styles.navigation)}>
        <Button settings={{ width: '96px' }} variant="secondary" onClick={prevStepHandle}>
          <p {...stylex.props(styles.btnText, styles.prevBtn)}>Назад</p>
        </Button>
        {currentStep !== 1 ? (
          <Button settings={{ width: '96px' }} onClick={nextStepHandle}>
            <p {...stylex.props(styles.btnText)}>Далее</p>
          </Button>
        ) : (
          <Button variant="accent" settings={{ width: '96px' }} onClick={createEventHandler}>
            <p {...stylex.props(styles.btnText)}>Создать</p>
          </Button>
        )}
      </div>
    </>
  );
};

export default CreateEventForm;
